import axios from 'axios';

import router from '@/router';

export default {
    async checkLoggedIn({ getters, commit }) {
        try {
            let response = await axios.post(
                `${getters.getInternalLoginController + '/auth_only'}`,
                null,
                {
                    withCredentials: true,
                    crossDomain: true,
                },
            );

            if (response.data.success === true) {
                console.log('Logged in!');
                commit('setIsAuthenticated', true);
                commit('setCurrentLocationId', response.data.currentLocationId);
                commit(
                    'setCurrentLocationName',
                    response.data.currentLocationName,
                );
                commit(
                    'setAuthenticatedLocations',
                    response.data.authenticatedLocations,
                );
                return true;
            }
        } catch (error) {
            console.error('Could not login');
            console.error(error);
            commit('setIsAuthenticated', false);
            return false;
        }
    },
    async initialize() {
        try {
            let response = await axios.get(
                'http://localhost/coreSTORE/index.php/sales/customer_recent_sales/3',
                {
                    params: {},
                },
            );

            if (response.data) {
                console.log(response.data);
                return response.data;
            } else {
                console.log('No data');
                return null;
            }
        } catch (error) {
            console.log('Error');
        }
    },
    async logout({ getters, commit }) {
        try {
            await axios.post(
                `${getters.getInternalHomeController + '/logout/0'}`,
                null,
                {
                    withCredentials: true,
                    crossDomain: true,
                },
            );

            console.log('Logged out!');
            commit('setIsAuthenticated', false);
        } catch (error) {
            console.error('Could not logout');
            console.error(error);
            commit('setIsAuthenticated', false);
        }

        await router.push('/login');
    },
    async changeCurrentLocation({ getters }, locationId) {
        try {
            let formData = new FormData();
            formData.append('employee_current_location_id', locationId);

            await axios.post(
                `${
                    getters.getInternalHomeController +
                    '/set_employee_current_location_id'
                }`,
                formData,
                {
                    withCredentials: true,
                    crossDomain: true,
                },
            );

            router.go();
        } catch (error) {
            console.error(error);
        }
    },
};
